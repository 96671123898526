<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-3">
        <dx-util-popup
          ref="customerChargeFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          content-template="content"
          title="Customer Charge Form"
          position="center"
          width="60vw"
          height="auto"
          @hidden="onHidden"
        >
          <template #content>
            <div>
            <dx-util-form ref="customerChargeFormRef" :form-data="customerCharge" :col-count="2">
              <dx-util-simple-item data-field="accountNo" editor-type="dxNumberBox" :editor-options="accountNoOptions" />
              <dx-util-simple-item data-field="companyName" editor-type="dxTextBox" :editor-options="companyNameOptions" />
              <dx-util-simple-item data-field="storeId" editor-type="dxSelectBox" :editor-options="storeEditorOptions">
                <dx-util-required-rule message="Store is required" />
                <dx-util-label text="Store" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="warehouseId" editor-type="dxSelectBox" :editor-options="warehouseEditorOptions">
                <dx-util-required-rule message="Warehouse is required" />
                <dx-util-label text="Store" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="chargeType" editor-type="dxSelectBox" :editor-options="chargeTypeEditorOptions">
                <dx-util-required-rule message="Charge type is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="costGroup" editor-type="dxSelectBox" :editor-options="costGroupEditorOptions">
                <dx-util-required-rule message="Cost Group is required" />
                </dx-util-simple-item>
              <dx-util-simple-item data-field="amount" editor-type="dxNumberBox" :editor-options="amountEdtiorOptions">
                <dx-util-required-rule message="Amount is required" />
                </dx-util-simple-item>
              <dx-util-simple-item data-field="notes" editor-type="dxTextArea" :editor-options="notesEdtiorOptions">
                <dx-util-required-rule message="Notes is required" />
                </dx-util-simple-item>
            </dx-util-form>
          </div>
          </template>
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
        </dx-util-popup>

      </div>
    </div>

  </div>
</template>

<script>
import customerChargeService from '@/http/requests/finance/customerChargeService'
import costGroupService from '@/http/requests/finance/costGroupService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import companyService from '@/http/requests/company/companyService'
import { Notify } from '@robustshell/utils/index'

const customerCharge = {
  id: null,
  companyId: null,
  storeId: 0,
  invoiceNo: 0,
  costGroup: null,
  amount: null,
  chargeType: null,
  problemId: '',
  warehouseId: null,
  approvedBy: '',
  chargedAt: '',
  notes: '',
  companyName: '',
  storeName: '',
  costGroupName: '',
  accountNo: null,
}
export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    warehouses: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentRow: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      customerCharge,
      costGroups: [],
      stores: [],
      accountNo: '',
      chargeTypes: [
        { text: 'Charge', value: 'charge' },
        { text: 'Refund', value: 'refund' },
      ],
      buttonOptions: {
        text: 'GET',
        type: 'success',
        location: 'after',
        onclick: () => {
        },
      },
    }
  },
  computed: {
    accountNoOptions() {
      return {
        onKeyDown: e => {
          this.onFilterByAccountNo(e)
        },
        buttons: [
          {
            name: 'trash',
            location: 'after',
            options: {
              text: 'GET',
              type: 'success',
              onClick: () => {
                this.getCompanyByAccountNo()
              },
            },
          },
        ],
      }
    },
    warehouseOptions() {
      return this.warehouses.filter(el => el.value !== 0)
    },
    warehouseEditorOptions() {
      return {
        items: this.warehouseOptions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    costGroupEditorOptions() {
      return {
        items: this.costGroups,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    chargeTypeEditorOptions() {
      return {
        items: this.chargeTypes,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    storeEditorOptions() {
      return {
        items: this.stores,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    amountEdtiorOptions() {
      return {
        format: '$ #,##0.##',
      }
    },
    companyNameOptions() {
      return {
        disabled: true,
      }
    },
    notesEdtiorOptions() {
      return {
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        onClick: this.handleSubmit,
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.$refs.customerChargeFormPopupRef.instance.hide()
          this.$emit('form-closed')
        },
      }
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue) {
          this.$refs.customerChargeFormPopupRef.instance.show()
        } else {
          this.$refs.customerChargeFormPopupRef.instance.hide()
        }
      },
    },
    warehouses: {
      handler() {
        this.setDefaultWarehouse()
      },
    },
    currentRow: {
      handler(newValue) {
        if (newValue.id) {
          this.customerCharge.id = newValue.id
          this.customerCharge.companyId = newValue.companyId
          this.customerCharge.companyName = newValue.companyName
          this.customerCharge.invoiceNo = newValue.invoiceNo
          this.customerCharge.warehouseId = newValue.warehouseId
          this.customerCharge.amount = newValue.amount
          this.customerCharge.chargeType = newValue.chargeType
          this.customerCharge.approvedBy = newValue.approvedBy
          this.customerCharge.chargedAt = newValue.chargedAt
          this.customerCharge.notes = newValue.notes
          this.customerCharge.storeName = newValue.storeName

          // Find storeId by store name within the function below
          this.getStores(newValue.companyId)
          // Find costGroupId by cost group name
          this.setCostGroupId(newValue.costGroup)
        }
      },
    },
  },
  mounted() {
    this.getCostGroups()
  },
  methods: {
    onHidden() {
      this.$refs.customerChargeFormRef.instance.resetValues()
      this.customerCharge.companyId = 0
      this.customerCharge.costGroupName = ''
      this.customerCharge.storeName = ''
      this.customerCharge.id = null
      this.$refs.customerChargeFormPopupRef.instance.hide()
      this.stores = []
      this.setDefaultWarehouse()
      this.$emit('form-closed')
    },
    getCostGroups() {
      costGroupService.fetchAllList().then(result => {
        const { data } = result
        this.costGroups.splice(0, this.costGroups.length)
        data.forEach(element => {
          this.costGroups.push({ value: element.id, text: element.name })
        })
      })
    },
    getStores(companyId) {
      this.stores.splice(0, this.stores.length)
      commonEntityService.fetchStoresByParentCompany(companyId).then(result => {
        const data = result.data
        data.forEach(element => {
          this.stores.push({ value: element.id, text: element.name })
        })
        if (this.customerCharge.id) {
          this.customerCharge.storeId = this.stores.filter(el => el.text === this.customerCharge.storeName)[0].value
        }
      })
    },
    setCostGroupId(costGroupName) {
      this.customerCharge.costGroup = this.costGroups.filter(el => el.text === costGroupName)[0].value
    },
    setDefaultWarehouse() {
      if (this.warehouseOptions.length === 1) {
        this.customerCharge.warehouseId = this.warehouseOptions[0].value
      }
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.customerCharge.accountNo = e.event.target.value.toString()
          this.getCompanyByAccountNo()
        }
      }
    },
    getCompanyByAccountNo() {
      if (!this.customerCharge.accountNo || this.customerCharge.accountNo === null || this.customerCharge.accountNo === 0
            || this.customerCharge.accountNo === '' || this.customerCharge.accountNo === undefined || this.customerCharge.accountNo.length < 3) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(this.customerCharge.accountNo).then(result => {
        this.customerCharge.companyId = result.data.id
        this.customerCharge.companyName = result.data.name
        this.getStores(this.customerCharge.companyId)
      })
    },
    handleSubmit() {
      const result = this.$refs.customerChargeFormRef.instance.validate()
      if (result.isValid) {
        if (this.customerCharge.id) {
          customerChargeService.update(this.customerCharge).then(() => {
            this.$emit('form-saved')
          })
        } else {
          customerChargeService.create(this.customerCharge).then(() => {
            this.$emit('form-saved')
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
